<template>
  <div>
    <v-card class="mt-5" min-height="200px">
      <div class="ml-9 mr-7">
        <div class="pt-10">
          <div class="font-14px">
            タグ
          </div>
          <validation-provider v-slot="{ errors }" name="media_tag_id" rules="">
            <v-autocomplete
              :error-messages="errors"
              :error="errors.length !== 0"
              :hide-details="errors.length <= 0"
              outlined
              multiple
              dense
              color="#13ABA3"
              class="font-16px mt-1 pb-3"
              :items="getAllMediaTags"
              item-text="name"
              item-value="id"
              placeholder="選択してください"
              v-model="mediaTag.value"
            >
              <template v-slot:selection="{ attrs, item, select, selected }">
                <v-chip
                  color="#D4F8F6"
                  class="mt-1"
                  v-bind="attrs"
                  :input-value="selected"
                  @click="select"
                >
                  <span>{{ item.name }}</span>
                  <v-avatar size="26" right @click="removeItem(item.id)">
                    <v-icon size="22">$GreenCross</v-icon>
                  </v-avatar>
                </v-chip>
              </template>
            </v-autocomplete>
          </validation-provider>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'ColumnTag',

  data() {
    return {}
  },
  props: {
    mediaTag: {
      required: true
    }
  },
  computed: {
    ...mapGetters(['getAllMediaTags'])
  },
  created() {
    this.getDataFromApi()
  },
  methods: {
    removeItem(id) {
      this.mediaTag.value = this.mediaTag.value.filter(item => item !== id)
    },
    async getDataFromApi() {
      await this.$store.dispatch('MEDIA_TAGS_GET_ALL', {
        sort_by: 'created_at',
        sort_by_order: 'desc',
        paginate: 100,
        page: 1
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.v-input__append-inner {
  display: none !important;
}
</style>
