var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"mt-5",attrs:{"min-height":"200px"}},[_c('div',{staticClass:"ml-9 mr-7"},[_c('div',{staticClass:"pt-10"},[_c('div',{staticClass:"font-14px"},[_vm._v(" タグ ")]),_c('validation-provider',{attrs:{"name":"media_tag_id","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"font-16px mt-1 pb-3",attrs:{"error-messages":errors,"error":errors.length !== 0,"hide-details":errors.length <= 0,"outlined":"","multiple":"","dense":"","color":"#13ABA3","items":_vm.getAllMediaTags,"item-text":"name","item-value":"id","placeholder":"選択してください"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var select = ref.select;
var selected = ref.selected;
return [_c('v-chip',_vm._b({staticClass:"mt-1",attrs:{"color":"#D4F8F6","input-value":selected},on:{"click":select}},'v-chip',attrs,false),[_c('span',[_vm._v(_vm._s(item.name))]),_c('v-avatar',{attrs:{"size":"26","right":""},on:{"click":function($event){return _vm.removeItem(item.id)}}},[_c('v-icon',{attrs:{"size":"22"}},[_vm._v("$GreenCross")])],1)],1)]}}],null,true),model:{value:(_vm.mediaTag.value),callback:function ($$v) {_vm.$set(_vm.mediaTag, "value", $$v)},expression:"mediaTag.value"}})]}}])})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }